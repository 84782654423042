import React from 'react'

import '../components/bootstrap.min.css';
import '../components/vendor/tether/tether.min.css';
import '../components/vendor/bootstrap/css/bootstrap.min.css';
import '../components/css/fonts/express-icons.css';
import '../components/css/card.css';

import '../components/vendor/font-awesome/css/font-awesome.min.css';
import '../components/vendor/bootstrap/css/glyphicon.css';
import '../components/vendor/cubeportfolio/css/cubeportfolio.min.css';

//import '../components/vendor/ion-icons/css/ionicons.min.css',
import '../components/vendor/owl-carousel/owl.theme.css';
import '../components/vendor/owl-carousel/owl.carousel.css';
import '../components/vendor/magnific-popup/magnific-popup.css';
import '../components/vendor/lite-tooltip/css/litetooltip.css';
import '../components/vendor/PricingTablesInspiration/css/component.css';
import '../components/vendor/PricingTablesInspiration/css/icons.css';
import '../components/vendor/PricingTablesInspiration/css/demo.css';
//Theme CSS
import '../components/css/main.css';
import '../components/css/main-shortcodes.css';
import '../components/css/header.css';
import '../components/css/form-element.css';
import '../components/css/animation.css';
import '../components/css/bg-stylish.css';
import '../components/css/blog.css';
import '../components/css/streamline-icon.css';
import '../components/css/responsive.css';
import '../components/css/utilities.css';
import '../components/css/portfolio.css';
import '../components/css/font-icons.css';
import '../components/css/skins/default.css';

//Theme Custom CSS
import '../components/css/custom.css';

import Footer from "../components/footer";
import Header from "../components/header";



const IndexPage = () => (
<div class="wrapper">
<Header/>
<div class="page-inner p-none">



<section class="section-primary main-color">
   <div class="container">
      

      <div class="row justify-content-center text-center mt-30">
         <div class="col-md-8">

            <form action="#" method="post">
               <div class="form-group">
                  <div class="row">

                     <div class="col-sm-4">
                        <label>Your name <span class="required">*</span></label>
                        <input type="text" value="" maxlength="100" class="form-control" name="name" id="name"/>
                     </div>
                     <div class="col-sm-4">
                        <label>Your email address <span class="required">*</span></label>
                        <input type="email" value="" maxlength="100" class="form-control" name="email" id="email1"/>
                     </div>
                     <div class="col-sm-4">
                        <label>Website</label>
                        <input type="text" value="" maxlength="100" class="form-control" name="website" id="website"/>
                     </div>
                  </div>
               </div>
               <div class="form-group">
                  <div class="row">

                     <div class="col-sm-12">
                        <label>Comment <span class="required">*</span></label>
                        <textarea maxlength="5000" rows="10" class="form-control" name="comment" id="Textarea1"></textarea>
                     </div>
                  </div>
               </div>
               <div class="row">
                  <div class="col-sm-12">
                     <br/>
                     <button name="submit" type="submit" id="sendmesage" class="btn btn-primary btn-md">Send Message</button>
                  </div>
               </div>
            </form>

         </div>
      </div>
   </div>
</section>




</div>
<Footer/>
</div>
)

export default IndexPage
